import { CSSObject } from '@emotion/core';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { ChangeEvent, HTMLProps, useEffect, useState } from 'react';

import Legend from '@components/controls/Legend';

import { Layout, VisuallyHidden, colors, scale } from '@scripts/gds';
import { PRICE_UNITS, PRICE_UNITS_ENUM_VALUES } from '@scripts/enums';

import MinusIcon from '@icons/24/minus.svg';
import PlusIcon from '@icons/24/plus.svg';

export interface CounterProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    /** Input unique name. Used for name and id properties */
    name: string;
    /** Initial input value */
    initialValue?: number;
    /** Input value */
    value?: number;
    /** Label text */
    label: string;
    /** Minimum value */
    min?: number;
    /** Maximum value */
    max?: number;
    /** Step value */
    step?: number;
    /** Handler change event on input */
    onChange?: (value: number) => void;
    /** Visually hidden legend */
    isHiddenLegend?: boolean;
    /** Flag to change view counter */
    vertical?: boolean;
    /** Required field */
    required?: boolean;
    /** Hint text */
    hint?: string;
    /** Formik field object (inner) */
    field?: FieldInputProps<number>;
    /** Formik meta object (inner) */
    meta?: FieldMetaProps<any>;
    /** Formik helpers object (inner) */
    helpers?: FieldHelperProps<number>;
    /** Special styles for buy button */
    isBuyBtn?: boolean;
    /** The flag responsible for the presence of multiplicity in the picker */
    isFullWidth?: boolean;
    /** The unit of measurement of the product */
    priceUnit?: PRICE_UNITS;

}

const Counter = ({
    isBuyBtn,
    name,
    initialValue = 1,
    value,
    label,
    step = 1,
    min = 0,
    max = 999,
    onChange,
    isHiddenLegend = true,
    vertical = false,
    required,
    hint,
    field,
    meta,
    helpers,
    disabled = false,
    isFullWidth = false,
    priceUnit = PRICE_UNITS.PIECE,
    ...props
}: CounterProps) => {
    const [innerValue, setInnerValue] = useState<string | number>(initialValue);

    const stepDigitsAfterDecimal = `${step}`.split('.')[1]?.length;

    const remainderDivisionFractionalNumbers = (numerator: string | number, denominator: number) => {
        const precision = Math.pow(10, stepDigitsAfterDecimal || 1); // Цифры после запятой

        const wholeNumerator = Math.round(+numerator * precision);
        const wholeDenominator = Math.round(denominator * precision);

        const remainder = wholeNumerator % wholeDenominator;

        return remainder / precision;
    }

    useEffect(() => {
        if (value) setInnerValue(value);
    }, [value]);

    const changeValue = (newValue: number) => {
        let value;

        if (Number.isInteger(step)) {
            switch (true) {
                case newValue >= max: value = max;
                    break;
                case newValue % step === 0: value = newValue;
                    break;
                default: {
                    const valueWithStep = newValue + (step - newValue % step);
                    value = valueWithStep > max ? max : valueWithStep;
                }
            }
        } else {
            switch (true) {
                case newValue >= max: value = max;
                    break;
                case remainderDivisionFractionalNumbers(innerValue, step) === 0: value = newValue;
                    break;
                default: {
                    const roundingUp = Math.ceil(newValue / step) * step;
                    value = roundingUp > max ? max : roundingUp;
                }
            }
        }
        setInnerValue(value);
        if (onChange) onChange(value);
        if (helpers) helpers.setValue(value);
    };

    const handleChangeInputValue = (value: string) => {
        const newValue = +value;
        if (newValue < min) {
            changeValue(min);
            return;
        }
        if (newValue > max) {
            changeValue(max);
            return;
        }
        changeValue(newValue);
    };

    const handleInputBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
        handleChangeInputValue(target.value);
        changeValue(+(+target.value).toFixed(stepDigitsAfterDecimal));
    };

    const buttonCSS: CSSObject = {
        backgroundColor: colors.secondaryOnDark,
        stroke: colors.white,
        height: `${isFullWidth ? 48 : 29}px`,
        width: `${isFullWidth ? 48 : 29}px`,
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':disabled': { backgroundColor: colors.textNuanceLight, cursor: 'not-allowed' },
        ':hover': { backgroundColor: colors.primary, transition: 'color ease 200ms' },
    };

    const iconCSS: CSSObject = {
        verticalAlign: 'middle',
        fill: 'white',
        transition: `fill ease 300ms`,
        width: scale(3, true),
    };

    return (
        <div {...props} css={{ width: '100%' }}>
            {isHiddenLegend ? (
                <VisuallyHidden>
                    <Legend name={name} label={label} required={required} hint={hint} meta={meta} />
                </VisuallyHidden>
            ) : (
                <Legend name={name} label={label} required={required} hint={hint} meta={meta} />
            )}
            <div
                css={{
                    width: '100%',
                    height: '48px',
                }}
            >
                <Layout css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: `${isFullWidth ? 48 : 29}px`,
                    gridGap: 0,
                }}>
                    <Layout.Item area="button-decrease">
                        <button
                            type="button"
                            onClick={() => changeValue(+(+innerValue - +step).toFixed(stepDigitsAfterDecimal))}
                            disabled={Number(innerValue) < min + step || disabled}
                            title={`Уменьшить на ${step}`}
                            css={{...buttonCSS}}
                        >
                            <MinusIcon css={iconCSS}/>
                        </button>
                    </Layout.Item>
                    <Layout.Item area="input" css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <input
                            type="number"
                            name={name}
                            id={name}
                            {...field}
                            value={innerValue}
                            step={step}
                            onChange={({target}) =>
                                target.value ? setInnerValue(Number(target.value)) : setInnerValue('')
                            }
                            onBlur={handleInputBlur}
                            onClick={({currentTarget}) => currentTarget.select()}
                            css={{
                                width: '100%',
                                height: `${isFullWidth ? 48 : 29}px`,
                                margin: '0 4px',
                                border: `1px solid ${colors.borderGrey}`,
                                borderRadius: '4px',
                                textAlign: 'center',
                                fontSize: '1.2rem',
                                fontWeight: 500,
                                color: colors.textMainBlack,
                                ':focus': {outlineOffset: -2, outline: 'none'},
                                '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                                    display: 'none',
                                },
                            }}
                        />
                    </Layout.Item>
                    <Layout.Item area="button-increase">
                        <button
                            type="button"
                            onClick={() => changeValue(+(+innerValue + +step).toFixed(stepDigitsAfterDecimal))}
                            disabled={Number(innerValue) >= max || disabled}
                            title={`Увеличить на ${step}`}
                            css={{...buttonCSS}}
                        >
                            <PlusIcon css={iconCSS}/>
                        </button>
                    </Layout.Item>
                </Layout>
                {step > 1 && !isFullWidth && (
                    <p css={{ textAlign: 'left', fontSize: '0.75rem', lineHeight: '20px' }}>
                        <span css={{ color: colors.textSecondGrey }}>Кратность</span>
                        {' '}
                        <span css={{ color: colors.textMainBlack }}>{step}</span>
                        {' '}
                        <span dangerouslySetInnerHTML={{__html: PRICE_UNITS_ENUM_VALUES[priceUnit]}}/>
                    </p>
                )}
            </div>
        </div>
    );
};

export default Counter;
